import React, { useEffect } from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import AOS from "aos";
import mainImage from "./Images/1.jpg";
import { HiThumbUp, HiDocument } from "react-icons/hi";
import { GrTechnology } from "react-icons/gr";
import { IoRocketSharp } from "react-icons/io5";
import { MdOutlineEqualizer } from "react-icons/md";
import { Helmet } from "react-helmet";

import "./App.css";

export default function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="app-container">
      <Helmet>
        <title>Bin Khamees General Trading Co</title>
        <meta name="title" content="Bin Khamees General Trading Co" />
        <meta
          name="description"
          content="To lead the MENA region with unique consumer solution problems and become the most trusted company in the region."
        />
        <link rel="canonical" href="https://binkhamees.com" />
        <meta name="keywords" content="trading, solution, innovation" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://binkhamees.com/" />
        <meta property="og:title" content="Bin Khamees General Trading Co" />
        <meta
          property="og:description"
          content="To lead the MENA region with unique consumer solution problems and become the most trusted company in the region."
        />
        <meta property="og:image" content="" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://binkhamees.com/" />
        <meta
          property="twitter:title"
          content="Bin Khamees General Trading Co"
        />
        <meta
          property="twitter:description"
          content="To lead the MENA region with unique consumer solution problems and become the most trusted company in the region."
        />
        <meta property="twitter:image" content=""></meta>
      </Helmet>
      <main>
        <section>
          <div className="image-container">
            <Image src={mainImage} alt="image" className="image-wrapper" />
            <div className="app-main-container" data-aos="fade-up">
              Bin Khamees General Trading Co
            </div>
          </div>
        </section>
        <section className="app-main-about-me">
          <Row
            style={{
              height: "100%",
              padding: "0px",
              margin: "0px",
            }}
          >
            <Col
              xs={12}
              sm={4}
              style={{
                background: "#9C7875",
                padding: "25px",
                margin: "0px",
              }}
            >
              <p className="app-header-text">Vision</p>
              <p className="app-subheader-text">
                To lead the MENA region with unique consumer solution problems
                and become the most trusted company in the region.
              </p>
            </Col>
            <Col
              xs={12}
              sm={4}
              style={{
                background: "#394267",
                padding: "25px",
                margin: "0px",
              }}
            >
              <p className="app-header-text">Mission</p>
              <p className="app-subheader-text">
                We strive to offer solutions to different problems consumers
                face in the best way possible.
              </p>
            </Col>
            <Col
              xs={12}
              sm={4}
              style={{
                background: "#9C7875",
                padding: "25px",
                margin: "0px",
              }}
            >
              <p className="app-header-text">About us</p>
              <p className="app-subheader-text">
                A family owned company based in Kuwait that specializes in
                introducing the best products and services to different
                stakeholders.
              </p>
            </Col>
          </Row>
        </section>
        <section className="values-container">
          <Container className="app-about-container">
            <p
              className="app-header-text"
              style={{
                color: "black",
              }}
            >
              Values
            </p>
            <Row>
              <Col xs={12} sm={4}>
                <div>
                  <div className="values-header-container">
                    <div>
                      <HiThumbUp size={40} />
                    </div>
                    <div className="values-header-text">Professionalism</div>
                  </div>
                  <div className="values-body-text">
                    We are dedicated to continuously develop our abilities to
                    guarantee the efficiency of our operational processes, with
                    full adherence to apply the principles of professional
                    performance and behavior such as: confidence, honesty,
                    cooperation, credibility, respect and responsibility which
                    regulate our relations with all parties.
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div>
                  <div className="values-header-container">
                    <div>
                      <GrTechnology size={40} />
                    </div>
                    <div className="values-header-text">Innovation</div>
                  </div>
                  <div className="values-body-text">
                    We are keen to create a working environment which encourages
                    and motivates innovation and mastery, supported by an
                    experienced team.
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div>
                  <div className="values-header-container">
                    <div>
                      <IoRocketSharp size={40} />
                    </div>
                    <div className="values-header-text">Development</div>
                  </div>
                  <div className="values-body-text">
                    We aim at employing modern and innovative technological
                    solutions and techniques which are our basic method in
                    serving our customers, not only a partner who obtains the
                    utmost preference and confidence of customers, but also to
                    achieve our vision in the long term.
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div>
                  <div className="values-header-container">
                    <div>
                      <MdOutlineEqualizer size={40} />
                    </div>
                    <div className="values-header-text">Quality</div>
                  </div>
                  <div className="values-body-text">
                    We aim at offering a high level of quality in the services
                    we provide and we execute.
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div>
                  <div className="values-header-container">
                    <div>
                      <HiDocument size={40} />
                    </div>
                    <div className="values-header-text">Commitment</div>
                  </div>
                  <div className="values-body-text">
                    We are fully committed to all services we offer to our
                    customers.
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}></Col>
            </Row>
          </Container>
        </section>
      </main>
      <footer
        className="app-main-footer"
        style={{ backgroundColor: "#F1F1F3" }}
      >
        <Row
          style={{
            padding: "0px",
            margin: "0px",
          }}
        >
          <Col
            xs={12}
            sm={6}
            style={{
              padding: "30px",
            }}
          >
            <Container className="app-footer-container">
              <p className="app-contact-header">Contact us</p>
              <div>
                <p
                  className="app-subcontact-header"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Email
                </p>
                <p className="app-subcontact-header">info@binkhamees.com</p>
              </div>
            </Container>
          </Col>
          <Col
            xs={12}
            sm={6}
            style={{
              padding: "30px",
            }}
          >
            <Container className="app-footer-container">
              <p className="app-contact-header">Address</p>
              <div>
                <p className="app-subcontact-header">
                  Kuwait City, Jibla Area, Fahad Al-Salem St., Al-Jon Tower, 3th
                  floor
                </p>
              </div>
            </Container>
          </Col>
        </Row>
      </footer>
    </div>
  );
}
